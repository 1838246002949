<template>
  <div class="container-fluid mt-4">
    <!-- Navigation -->
    <base-nav
      id="event-main-navigation"
      container-classes="container-fluid"
      class="navbar fixed-top navbar-horizontal navbar-expand-lg navbar-dark"
      type="gradient-base"
      v-model="showMenu"
    >
      <a slot="brand" class="navbar-brand" href="#">
        <img :src="logo" />
      </a>
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <router-link to="/">
              <img :src="logo" />
            </router-link>
          </div>

          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              @click="showMenu = false"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav ml-lg-auto">
        <li class="nav-item">
          <a class="nav-link nav-link-icon" href="#speaker-details">
            <i class="far fa-comment-alt"></i>
            Details
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            href="#speaker-gallery-media"
            id="navbar-default_dropdown_1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-image"></i>
            Gallery
          </a>
        </li>

        <li>
          <a
            class="nav-link nav-link-icon"
            href="#speaker-happening"
            id="navbar-default_dropdown_1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fa fa-map"></i>
            Location
          </a>
        </li>

        <li class="nav-item dropdown mt-2">
          <!-- ToDo - Develop Mobile Navigation-->
          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbar-default_dropdown_1"
          >
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </div>
        </li>
      </ul>
    </base-nav>
    <div
      class="mb-3 mt--2"
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <!-- Back  -->
      <back-button @click="goBack" />

      <div style="justify-content: end">
        <base-button size="sm" outline type="primary" @click="editSpeaker()">
          <i class="far fa-edit" />Edit Speaker
        </base-button>
        <base-button
          size="sm"
          type="primary"
          @click="copyPublicLink()"
          class="mt-2"
        >
          <i class="fa fa-link" />Public Link
        </base-button>
      </div>
    </div>

    <base-alert dismissible type="primary">
      <strong> <i class="fa fa-info-circle" /> Preview Speaker Page - </strong>
      This page cannot be accessed from other users, in order to share this
      speaker with audience please use the 'Public Link'.
    </base-alert>
    <div
      v-if="skeletonLoader"
      class="container-fluid"
      style="max-width: 83.33333333%"
    >
      <el-skeleton :rows="6" animated class="mt-4">
        <template slot="template">
          <div style="padding: 14px">
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; height: 100px"
            /><br /><br />
            <el-skeleton-item
              variant="image"
              style="width: 100%; height: 480px"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 50%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 30%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 40%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 100%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 100%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 30%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 40%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 0; width: 30%"
            /><br />
            <el-skeleton-item
              variant="text"
              style="margin-right: 1%; width: 32%"
            />
            <el-skeleton-item
              variant="text"
              style="margin-right: 1%; width: 32%"
            />
            <el-skeleton-item
              variant="text"
              style="margin-right: 1%; width: 32%"
            />
          </div>
        </template>
      </el-skeleton>
    </div>

    <base-speaker-page v-if="!skeletonLoader" :item="item" :modals="modals" />

    <!-- Footer-->
    <hr />
    <div class="row justify-content-between">
      <div class="col-md-4">
        <div class="copyright text-center">
          <small class="text-muted"> Powered by </small>
          <a href="http://www.bookmeinscotty.com">
            <img
              src="/img/brand/bmis-default-full-logo_italic_bck.png"
              style="max-width: 35%; max-height: 3rem"
            />©
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div class="copyright text-center">
          <small class="text-muted mr-4"
            >© {{ copyrightYear }} | All Rights Reserved</small
          >
        </div>
      </div>

      <div class="col-md-3">
        <div class="copyright text-center">
          <small class="text-muted">
            Made with <i class="fa fa-heart heart" /> by BMIS Team
          </small>
        </div>
      </div>
    </div>
    <hr />
  </div>
</template>

<script>
import {
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem,
} from "element-ui";
import swal from "sweetalert2";
import BackButton from "@/components/BackButton.vue";
import BaseSpeakerPage from "@/components/Speakers/BaseSpeakerPage.vue";

export default {
  name: "PreviewSpeakerItem",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Image.name]: Image,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Skeleton.name]: Skeleton,
    [SkeletonItem.name]: SkeletonItem,
    BackButton,
    BaseSpeakerPage,
  },
  data() {
    return {
      item: {
        id: null,
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      modals: {
        openBooking: false,
        openVideo: false,
      },
      showMenu: false,
      attendee: { name: "", email: "", type: "attendees" },
      skeletonLoader: false,
      copyrightYear: new Date().getFullYear(),
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    logo() {
      return process.env.VUE_APP_DEFAULT_LOGO;
    },
  },
  async created() {
    // Data fetched and ready to manipulate on this component
    await this.fetchItem();
  },
  methods: {
    async fetchItem() {
      this.skeletonLoader = true;
      try {
        const id = (this.item.id = this.$route.params.id);
        let params = {
          filter: {
            ...(this.user
              ? { contractor_id: this.user.id }
              : { contractor_id: 0 }),
          },
          include: "categories",
        };

        await this.$store.dispatch("speakers/get", { id, params }).then(() => {
          this.item = this.$store.getters["speakers/speaker"];
          setTimeout(() => {
            this.skeletonLoader = false;
          }, "1000");
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    goBack() {
      this.$router.push({ name: "List Speakers" });
    },
    editSpeaker() {
      this.$router.push({
        name: "Edit Speaker",
        params: { id: this.item.id },
      });
    },
    eventPublicURL() {
      const itemId = _.isEmpty(this.item.hash_code)
        ? this.item.id
        : this.item.hash_code;
      return process.env.VUE_APP_BASE_URL + "/speakers/" + itemId;
    },
    bookButtonPublicURL() {
      return this.eventPublicURL() + "?btnOnly=true";
    },
    copyPublicLink() {
      if (this.user) {
        try {
          navigator.clipboard.writeText(this.eventPublicURL());
          swal.fire({
            title: "Copied - Public Speaker Link",
            html:
              "You can use this link to share it with a wide group of people, post it on your Social Media or Spread the Word<br></hr> " +
              "<br><b>Public Speaker Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
              this.eventPublicURL() +
              "</button> <br></hr>",
            type: "success",
            confirmButtonClass: "btn btn-outline-danger btn-fill",
            confirmButtonText: " Close",
            buttonsStyling: false,
            icon: "success",
          });
        } catch ($e) {
          alert("Cannot copy");
        }
      }
    },
    async copyIframeButtonLink() {
      if (this.user) {
        try {
          const publicURL = this.bookButtonPublicURL();
          const iFrameCodeblock =
            "<iframe src=" +
            publicURL +
            " width=100%; height=100%; style=border:none;" +
            "></iframe>";
          navigator.clipboard.writeText(iFrameCodeblock).then(
            function () {
              alert("Embeddable Ticket Widget - Copied succesfully!");
            },
            function () {
              alert("Booking Ticket iFrame link copy failed!");
            }
          );
          swal.fire({
            title: "Copied - Public Book Me In Scotty Ticket Link",
            html:
              "You can use the below iframe to embedd the Book Me In Scotty ticket popup of your speaker to any website, your social media or as web widget etc.<br></hr> " +
              "<br><b>as embed iFrame:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
              "<'" +
              "iframe src='https://app.bookmeinscotty.com/...' width='100%' height='100%' style='border:none;'" +
              "><" +
              "'/iframe'" +
              ">" +
              "</button> <br></hr>",
            // text: this.iFrameCodeblock,
            type: "success",
            confirmButtonClass: "btn btn-outline-danger btn-fill",
            confirmButtonText: " Close",
            buttonsStyling: false,
            icon: "success",
          });
        } catch ($e) {
          alert("Cannot copy");
        }
      }
    },
  },
};
</script>
