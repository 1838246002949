<template>
  <div class="container-fluid mt-3">
    <!-- Back  -->
    <!-- <el-page-header @back="goBack" style="color: #5e72e4;"/> -->
    <back-button @click="goBack" />
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">
        <div class="row justify-content-center mt-3">
          <div class="col-xl-6 order-xl-1">
            <transition name="el-zoom-in-center">
              <card>
                <div slot="header" class="row align-items-center">
                  <div class="col-md-8">
                    <h3 class="mb-0">Stet up the profile of the speaker</h3>
                  </div>
                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        label="First Name *"
                        name="First Name"
                        type="text"
                        placeholder="First name of the speaker"
                        v-model="speaker.first_name"
                        required
                      />
                      <validation-error
                        :errors="apiValidationErrors.first_name"
                      />
                    </div>
                    <div class="col-md-6">
                      <base-input
                        label="Last Name *"
                        name="Last Name"
                        type="text"
                        placeholder="Last name of the speaker"
                        v-model="speaker.last_name"
                        required
                      />
                      <validation-error
                        :errors="apiValidationErrors.last_name"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <base-input
                        label="City *"
                        name="City"
                        type="text"
                        placeholder="City of the speaker"
                        v-model="speaker.city"
                        required
                      />
                      <validation-error :errors="apiValidationErrors.city" />
                    </div>
                    <div class="col-md-6">
                      <base-input label="Country" required>
                        <el-select
                          v-model="speaker.country"
                          filterable
                          prepend-icon="fas fa-user"
                          placeholder="Select..."
                        >
                          <el-option
                            v-for="country in getCountriesList()"
                            :key="country"
                            :label="country"
                            :value="country"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <base-input
                    type="text"
                    label="Expert credentials *"
                    success-message="Summarise youe expertise on the subjects you'll likely be talking about. Unlike bio, this should be short and to the point'"
                  >
                    <textarea
                      v-model="speaker.expertise"
                      placeholder="Summarise youe expertise on the subjects you'll likely be talking about. Unlike bio, this should be short and to the point'"
                      class="form-control"
                      rows="3"
                      name="Expertise"
                      required
                    >
                    </textarea>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.expertise" />
                  <base-input
                    type="text"
                    label="Bio *"
                    success-message="With bio you can go into a bit more detail about your background."
                  >
                    <textarea
                      v-model="speaker.biography"
                      placeholder="With bio you can go into a bit more detail about your background."
                      class="form-control"
                      rows="3"
                      name="Biography"
                      required
                    >
                    </textarea>
                  </base-input>
                  <validation-error :errors="apiValidationErrors.biography" />
                </div>
              </card>
            </transition>
          </div>
          <div class="col-xl-6 order-xl-1">
            <card header-classes="bg-transparent" name="details">
              <div slot="header" class="row align-items-center">
                <div class="col-md-8">
                  <h3 class="mb-0">Provide more details</h3>
                </div>
              </div>
              <div class="card-body">
                <base-input label="Timezone" required>
                  <el-select
                    v-model="speaker.timezone"
                    filterable
                    prepend-icon="fas fa-user"
                    placeholder="Select..."
                  >
                    <el-option
                      v-for="speaker in allTimezones"
                      :key="speaker"
                      :label="speaker"
                      :value="speaker"
                    >
                    </el-option>
                  </el-select>
                </base-input>
                <base-input label="Available for">
                  <base-switch
                    class="mr-1"
                    v-model="speaker.virtual"
                    :description="{
                      show: true,
                      content: 'Virtual events',
                    }"
                  ></base-switch>
                  <base-switch
                    class="mr-1"
                    v-model="speaker.physical"
                    :description="{
                      show: true,
                      content: 'In-person events',
                    }"
                  ></base-switch>
                  <base-switch
                    class="mr-1"
                    v-model="speaker.podcast"
                    :description="{
                      show: true,
                      content: 'Podcast interviews',
                    }"
                  ></base-switch>
                </base-input>

                <base-input label="Categories">
                  <el-select
                    v-model="speaker.speaker_categories"
                    multiple
                    placeholder="Select..."
                  >
                    <el-option
                      v-for="single_category in allCategories"
                      :key="single_category.id"
                      :value="single_category.id"
                      :label="single_category.name"
                    >
                    </el-option>
                  </el-select>
                </base-input>

                <base-input label="Contacts">
                  <el-tabs
                    v-model="dynamicTabs.editableTabsValue"
                    id="dynamic-tabs"
                    type="card"
                    :stretch="true"
                    editable
                    @edit="handleDynamicTabs"
                  >
                    <el-tab-pane
                      v-for="tab_ in dynamicTabs.editableTabs"
                      :key="tab_.sort"
                      :label="tab_.title"
                      :name="tab_.name"
                      :lazy="true"
                    >
                      <base-input label="Contact type *" required>
                        <el-select
                          v-model="tab_.type"
                          filterable
                          prepend-icon="fas fa-user"
                          placeholder="Select..."
                        >
                          <el-option
                            v-for="contactType in contactTypes"
                            :key="contactType"
                            :label="contactType"
                            :value="contactType"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                      <base-input
                        label="Enter contact *"
                        v-model="tab_.value"
                        name="Contact value"
                        required
                      />
                    </el-tab-pane>
                  </el-tabs>
                </base-input>
              </div>
            </card>
            <!-- Sticky buttons -->
            <div id="sticky-buttons" class="my-4 text-right">
              <card type="frame">
                <div class="mt--3 mb--3">
                  <base-button
                    type="button"
                    class="btn btn-primary"
                    v-if="!loading"
                    data-testid="btn-create-item"
                    native-type="submit"
                  >
                    <i class="fa fa-save" /> Create Speaker
                  </base-button>
                  <div class="text-center" v-if="loading">
                    <slot name="loading">
                      <i class="fas fa-spinner fa-spin"></i>
                    </slot>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import moment from "moment-timezone";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { Select, Option, Tabs, TabPane } from "element-ui";
// components
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BackButton from "@/components/BackButton.vue";

export default {
  name: "AddSpeaker",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    ValidationError,
    BaseInput,
    BackButton,
  },
  mixins: [formMixin],
  data() {
    return {
      loading: false,
      file: null,
      allCategories: [],
      allTimezones: [],
      speaker: {
        type: "speakers",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        country: "Australia",
        speaker_categories: [],
      },
      showAddModal: false,
      dynamicTabs: {
        editableTabsValue: "",
        editableTabs: [
          {
            title: "New Contact",
            type: "Email",
            name: "1",
            value: "",
          },
        ],
        tabIndex: 1,
      },
      contactTypes: [
        "Email",
        "Phone",
        "Facebook",
        "Instagram",
        "LinkedIn",
        "Twitter",
      ],
    };
  },
  computed: {
    profileImage() {
      if (!_.isEmpty(this.speaker.profile_image)) {
        if (
          this.speaker.profile_image.includes("http://") ||
          this.speaker.profile_image.includes("https://")
        ) {
          return this.speaker.profile_image;
        }
        return process.env.VUE_APP_AWS_S3_URL + this.speaker.profile_image;
      }
      return (
        process.env.VUE_APP_BASE_URL +
        "/img/brand/bmis-default-asset-placeholder.png"
      );
    },
  },
  created() {
    this.auth();
    this.getCategories();
    this.allTimezones = moment.tz.names();
  },
  methods: {
    customFocus() {
      setTimeout(function () {
        document.getElementsByClassName("flatpickr-calendar")[0].focus();
      }, 10);
    },
    async auth() {
      try {
        // Fetch User data
        // await this.$store.dispatch("profile/me");
        this.user = await { ...this.$store.getters["profile/me"] };
      } catch (error) {
        // Notification
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong with your account!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Speakers" });
    },
    redirect(speaker) {
      this.$router.push({
        name: "Edit Speaker",
        params: { id: speaker.id },
      });
    },
    async onSubmit(values) {
      this.loading = true;
      this.speaker.contacts = [];

      this.dynamicTabs.editableTabs.forEach((contact) => {
        if (contact.type && contact.value) {
          this.speaker.contacts.push({
            type: contact.type,
            value: contact.value,
          });
        }
      });

      try {
        await this.$store.dispatch("speakers/add", this.speaker);
        this.speaker = await this.$store.getters["speakers/speaker"];
        this.loading = false;
        this.$notify({
          type: "success",
          message: "Speaker added successfully.",
        });

        this.redirect(this.speaker);
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
        this.setApiValidation(error.response.data.errors);
        if (this.speaker.id) {
          await this.$store.dispatch("speakers/destroy", this.speaker.id);
        }
      }
    },
    handleDynamicTabs(tabName, action) {
      if (action === "add") {
        let newTabName = ++this.dynamicTabs.tabIndex + "";
        this.dynamicTabs.editableTabs.push({
          title: "New Contact",
          name: newTabName,
          type: "Email",
          value: "",
        });
        this.dynamicTabs.editableTabsValue = newTabName;
      }
      if (action === "remove") {
        let tabs = this.dynamicTabs.editableTabs;
        let activeName = this.dynamicTabs.editableTabsValue;

        if (activeName === tabName) {
          tabs.forEach((tab, index) => {
            if (tab.name === tabName) {
              let nextTab = tabs[index + 1] || tabs[index - 1];
              if (nextTab) {
                activeName = nextTab.name;
              }
            }
          });
        }

        this.dynamicTabs.editableTabsValue = activeName;
        this.dynamicTabs.editableTabs = tabs.filter(
          (tab) => tab.name !== tabName
        );
      }
    },
    uploadAssetUrl(resource, previousImage = null) {
      return (
        process.env.VUE_APP_API_BASE_URL +
        "/uploads/speakers/" +
        this.$route.params.id +
        "/" +
        resource +
        "?previousImg=" +
        previousImage
      );
    },
    async getCategories() {
      try {
        await this.$store.dispatch("categories/list");
        this.allCategories = this.$store.getters["categories/dropdown"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>

<style></style>
