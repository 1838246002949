<template>
  <div class="container-fluid mt-3">
    <div class="help-container mb-1">
      <el-drawer
        title="I am the title"
        :visible.sync="drawer"
        :with-header="false"
      >
        <h4 class="mt-5 mb-3">Visual Guide:</h4>
        <vue-plyr>
          <div data-plyr-provider="youtube" data-plyr-embed-id="-B58GgsehKQ" />
        </vue-plyr>
        <hr />
        <h4>Quick Guide:</h4>
        <ul>
          <li>Follow BookMeInScotty on: <a href="#">Twitter</a>.</li>
          <li>
            Read and subscribe to our newsletter
            <a href="#">Book Me In Scotty Blog</a>.
          </li>
          <li>Follow Book Me In Scotty on <a href="#">Instagram</a>.</li>
          <li>
            Follow Me In Scotty on
            <a href="https://www.facebook.com/creativetim">Facebook</a>.
          </li>
        </ul>
        <hr />

        <h4>Help Center:</h4>
        <a href="#">Read the official documentation here</a>
      </el-drawer>
    </div>
    <div id="event-list-card">
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template v-slot:header>
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">
                My Speakers List
                <!--            <i class="fa fa-info-circle pointer-class" @click="drawer = true"/>-->
              </h3>

              <small class="text-muted">
                Showing {{ to }} of {{ total }} results

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </small>
            </div>

            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addSpeaker()">
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-add"></i
                ></span>
                <span class="btn-inner--text">New Speaker</span>
              </base-button>
            </div>
          </div>
        </template>

        <template>
          <div id="event-list-table">
            <div class="d-flex justify-content-center flex-wrap">
              <div class="col-10">
                <base-input
                  v-model="query"
                  type="search"
                  prepend-icon="fas fa-search"
                  placeholder="Type a name to search..."
                  clearable
                >
                  <small slot="append" class="font-weight-bold">
                    <el-dropdown
                      type="primary"
                      trigger="click"
                      :hide-on-click="dropdown.hideOnClick"
                      :placement="dropdown.placement"
                    >
                      <span class="el-dropdown-link" style="color: #adb5bd">
                        <i class="fa fa-filter" />
                      </span>
                      <template>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item icon="el-icon-circle-check">
                            <span
                              v-show="this.filter.upcomingSpeakers"
                              @click="filterAllSpeakers()"
                              >Show All My Speakers</span
                            >
                            <span
                              v-show="!this.filter.upcomingSpeakers"
                              @click="filterAllSpeakers()"
                              >Show Upcoming Speakers</span
                            >
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </small>
                </base-input>
              </div>
            </div>

            <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="speakers"
              @sort-change="sortChange"
            >
              <!-- Load Spinner -->
              <div slot="empty" v-if="loading">
                <img
                  src="/img/loading.gif"
                  style="height: 100px; width: 100px"
                />
              </div>

              <el-table-column
                label="ID"
                prop="id"
                sortable="custom"
                min-width="95px"
                align="center"
                v-if="isSuperUser()"
              />

              <el-table-column
                label="Name"
                prop="name"
                sortable="custom"
                min-width="260px"
                max-width="260px"
                align="left"
              >
                <template v-slot="{ row }">
                  <a
                    type="text"
                    @click="viewSpeaker(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <strong class="text-black-50">{{
                      row.first_name + " " + row.last_name
                    }}</strong>
                  </a>
                </template>
              </el-table-column>

              <el-table-column
                label="Profile Image"
                prop="profile_image"
                min-width="140px"
                max-width="150px"
                align="left"
              >
                <template v-slot="{ row }">
                  <img
                    v-if="row.profile_image"
                    :src="getProfileImage(row.profile_image)"
                    style="width: 100px; height: auto"
                    class="hero hero-thumbnail"
                    alt="placeholder"
                  />
                </template>
              </el-table-column>

              <el-table-column
                label="Categories"
                prop="categories"
                min-width="145px"
                align="left"
              >
                <template v-slot="{ row }">
                  <div v-for="category in row.categories" :key="category.id">
                    {{ category.name }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Created at"
                min-width="115px"
                align="left"
              >
                <template v-slot="{ row }">
                  <div class="ml-2">
                    <small
                      class="text-muted text-uppercase text-black-60 text-wrap"
                    >
                      {{ speakerCreatedAtTime(row.created_at) }}
                    </small>
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                label="Email"
                prop="email"
                min-width="165px"
                align="left"
              >
                <template v-slot="{ row }">
                  <div class="ml-2">
                    {{ getSpeakerEmail(row.contacts) }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="Actions" min-width="125px" align="left">
                <template v-slot="scope">
                  <div class="table-actions ml-2">
                    <el-tooltip content="Preview speaker" placement="top">
                      <a
                        type="text"
                        @click="viewSpeaker(scope.row)"
                        class="table-action"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="far fa-eye text-base"></i>
                      </a>
                    </el-tooltip>

                    <el-tooltip content="Edit speaker" placement="top">
                      <a
                        type="text"
                        @click="editSpeaker(scope.row)"
                        class="table-action"
                        data-toggle="tooltip"
                        style="cursor: pointer"
                      >
                        <i class="far fa-edit text-base"></i>
                      </a>
                    </el-tooltip>

                    <el-tooltip content="More options" placement="top">
                      <el-dropdown trigger="click" class="dropdown">
                        <span class="text-light ml-2">
                          <i class="fa fa-ellipsis-v text-base mt-2" />
                        </span>
                        <el-dropdown-menu
                          class="dropdown-menu dropdown-menu-arrow show text-center ml-3 mr-3"
                          slot="dropdown"
                        >
                          <div style="display: flex; flex-direction: column">
                            <base-button
                              type="button"
                              class="btn btn-sm btn-base ml-2 mb-2"
                              @click="onPublicLinkCopy(scope.row)"
                              data-toggle="tooltip"
                              style="cursor: pointer"
                              append
                            >
                              <i class="fa fa-link" /> Public link
                            </base-button>

                            <base-premium-overlay
                              :enabled="getUserRole() === 'creator'"
                              badge-label="Duplicate speaker"
                            ></base-premium-overlay>

                            <base-button
                              type="button"
                              class="btn btn-sm btn-base ml-2 mb-2"
                              :disabled="getUserRole() === 'creator'"
                              @click="cloneSpeaker(scope.row)"
                              data-toggle="tooltip"
                              style="cursor: pointer"
                              append
                            >
                              <i class="far fa-copy" />
                              Duplicate speaker
                            </base-button>

                            <base-button
                              type="button"
                              class="btn btn-sm btn-danger ml-2 mt-2"
                              @click="deleteSpeaker(scope.row.id)"
                              data-toggle="tooltip"
                              style="cursor: pointer"
                            >
                              <i class="far fa-trash-alt" /> Delete
                            </base-button>
                          </div>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>

        <template v-slot:footer>
          <div
            id="event-list-footer"
            class="row d-flex justify-content-md-between justify-content-center flex-wrap"
          >
            <div class="col-auto">
              <el-select
                class="select-primary pagination-select mb-1"
                style="width: 140px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary mt-1"
                  v-for="speaker in pagination.perPageOptions"
                  :key="speaker"
                  :label="speaker"
                  :value="speaker"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-auto">
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              />
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Drawer,
} from "element-ui";
import { BasePagination } from "@/components/";
import BasePremiumOverlay from "@/components/Premium/BasePremiumOverlay.vue";

import swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";

export default {
  layout: "ListSpeakers",
  components: {
    BasePagination,
    BasePremiumOverlay,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Drawer.name]: Drawer,
  },
  data() {
    return {
      drawer: false,
      query: null,
      filter: {
        pastSpeakers: false,
        upcomingSpeakers: true,
      },
      selectedRows: [],
      sort: "-start_at",
      total: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },
      dropdown: {
        hideOnClick: true,
        placement: "bottom-end",
      },
      speakers: [],
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      loading: true,
      lodash_: _,
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "fetchListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "fetchList",
      immediate: false,
      deep: true,
    },
  },
  methods: {
    fetchListDebounced: _.debounce(function () {
      this.fetchList();
    }, 300),
    async fetchList() {
      try {
        let params = {
          // ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.user
              ? { contractor_id: this.user.id }
              : { contractor_id: 0 }),
            ...(this.query ? { name: this.query } : {}),
            // ...(this.filter.upcomingSpeakers ? { upcoming_at: 1 } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "categories",
        };

        // fire a GET request to load the items
        await this.$store.dispatch("speakers/list", params).then(() => {
          this.speakers = this.$store.getters["speakers/list"];
          this.total = this.$store.getters["speakers/listTotal"];
        });

        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    async deleteSpeaker(id) {
      // Exclude Speakers from Delete

      // if (["1", "2"].includes(id)) {
      //   this.$notify({
      //     type: "danger",
      //     message: "You are not allowed not change data of default speakers.",
      //   });
      //   return;
      // }

      const confirmation = await swal.fire({
        title: `Delete this speaker?`,
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        confirmButtonClass: "btn btn-warning",
        cancelButtonClass: "btn btn-primary",
      });

      try {
        if (confirmation.value === true) {
          // if (this.user.roles.name === "member") {
          //   this.$notify({
          //     type: "danger",
          //     message: "Oops, you are not authorized to do this action.",
          //   });
          //   return;
          // }
          // send DELETE request to remove the Event
          await this.$store.dispatch("speakers/destroy", id).then(
            () => this.fetchList() // fetch to refresh the remaining Speaker list
          );

          this.$notify({
            type: "success",
            message: "Speaker deleted successfully.",
          });
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
    addSpeaker() {
      this.$router.push({ name: "Add Speaker" });
    },
    viewSpeaker(row) {
      this.$router.push({
        name: "Speaker Preview",
        params: { id: row.id },
      });
    },
    editSpeaker(row) {
      this.$router.push({
        name: "Edit Speaker",
        params: { id: row.id },
      });
    },
    cloneSpeaker(row) {
      this.$router.push({
        name: "Edit Speaker",
        params: { id: row.id },
        query: { clone: "true" },
      });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.fetchList();
    },
    getProfileImage(profile) {
      if (profile.includes("http://") || profile.includes("https://")) {
        return profile;
      }
      // return cloud url
      return process.env.VUE_APP_AWS_S3_URL + profile;
    },
    getPercentage(row) {
      return row.total_capacity === -1 ? false : true;
    },
    getCapacityTotal(row) {
      return (
        row.total_attendees +
        "/" +
        (row.total_capacity === -1 ? " -" : row.total_capacity)
      );
    },
    getCapacityPercentage(row) {
      return Math.round((row.total_attendees / row.total_capacity) * 100);
    },
    getAttendeesCount(row) {
      return row.total_attendees > 3
        ? "+" + (row.total_attendees - 3)
        : row.total_attendees;
    },
    goToGuestList(row) {
      // Redirect to preview
      this.$router.push({
        name: "List Guests",
        // params: { id: this.speaker.id },
        query: { speaker: row.id },
      });
    },
    getCalendarFile() {
      this.$ics.removeAllSpeakers();
      this.$ics.addSpeaker(
        navigator.language === "zz-ZZ" ? "en-AU" : navigator.language,
        this.speaker.name,
        this.speaker.description,
        this.speaker.venues ? this.speaker.venues.address_name : "",
        this.speaker.start_at,
        this.speaker.end_at,
        this.eventPublicURL,
        {},
        "",
        ""
      );
      this.$ics.download(this.speaker.name);
    },
    happeningTime(eventDate) {
      // Client-User DateΤimezone
      const currentTimezone = this.user.timezone ?? this.timezone;
      const today = moment().tz(currentTimezone);

      // Event DateTimezone
      const originalDTZ = moment.tz(eventDate.start_at, eventDate.timezone);
      const happening = originalDTZ.clone().tz(currentTimezone);

      return {
        date: happening.fromNow(),
        label: today.diff(happening, "hours") < 0 ? "success" : "warning",
      };
    },
    speakerCreatedAtTime(createdAt) {
      return moment(createdAt).format("ddd, DD MMM  @ hh:ss A");
    },
    eventPublicURL(speaker) {
      const speakerId = _.isEmpty(speaker.hash_code)
        ? speaker.id
        : speaker.hash_code;
      return process.env.VUE_APP_BASE_URL + "/speaker/" + speakerId;
    },
    copyPublicLink(speaker) {
      try {
        swal.fire({
          title: "Public link - Copied",
          html:
            "You can use this link to share it with a wide group of people, post it on your Social Media or Spread the Word<br></hr> " +
            "<br><b>Public Speaker Link:</b><br> <button type='button' class='btn-icon-clipboard text-center'>" +
            this.eventPublicURL(speaker) +
            "</button> <br></hr>",
          type: "success",
          confirmButtonClass: "btn btn-outline-danger btn-fill",
          confirmButtonText: " Close",
          buttonsStyling: false,
          icon: "success",
        });
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    onPublicLinkCopy(speaker) {
      const publicURL = this.eventPublicURL(speaker);
      navigator.clipboard.writeText(publicURL);
      this.$notify({
        type: "success",
        message: "Link Copied - " + publicURL,
      });
    },
    filterAllSpeakers() {
      this.filter.upcomingSpeakers = !this.filter.upcomingSpeakers;
      this.fetchList();
    },
    filterPastSpeakers() {
      this.filter.pastSpeakers = !this.filter.pastSpeakers;
      this.fetchList();
    },
    getSpeakerEmail(contacts) {
      const emailObject = contacts?.find((item) => item.type === "Email");
      return emailObject ? emailObject.value : "-";
    },
  },
};
</script>
